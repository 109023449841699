/*jshint -W079 */
var appCfg = {};

(function (appCfg) {
    'use strict';

    appCfg.appVersion = 1;
    appCfg.appName = 'Mellivora';
    appCfg.jsDir = '/js/Mellivora/src/';
    appCfg.viewDir = '/views/Mellivora/';
    appCfg.url = 'http://localhost:8085';
    appCfg.apiUrl = '/api';
    appCfg.menus = [];
    appCfg.publicStates = ['auth','activate_account','register.success','enable_two_factor'];
    appCfg.defaultCacheMin = 5;
    appCfg.boundApps = [];
    appCfg.routing = {
        prefix: '',
        html5Mode: false
    };
})(appCfg);
(function (angular, appCfg) {

    var app = angular.module(appCfg.appName, [
        'ui.router',
        'satellizer',
        'ngResource',
        //'toaster',
        'ngTable',
        'restangular',
        'ngAnimate',
        'ui.bootstrap',
        'ngFileUpload',
       // 'mgcrea.ngStrap',
        'checklist-model',
        'angular-ladda',
        'angular-cache',
        'localytics.directives',
        'datePicker',
        'google.places',
        'ui.select'
    ].concat(appCfg.boundApps))
    .filter('sumByColumn', function () {
        return function (collection, column) {
            var total = 0;
            collection.forEach(function (item) {
                total += parseInt(item[column]);
            });

            return total;
        };
    })
    .factory('preventTemplateCache', function($rootScope) {
        var version = $rootScope.version;
        return {
            'request': function(config) {
                if (config.url.indexOf('views') !== -1) {
                    config.url = config.url + '?t=' + 0.102121;
                }
                return config;
            }
        };
    })
    .config(function($stateProvider, $urlRouterProvider, $authProvider, $httpProvider, $provide,RestangularProvider,$uiViewScrollProvider,laddaProvider) {

            $httpProvider.interceptors.push('preventTemplateCache');

            laddaProvider.setOption({
                style: 'expand-left',
                spinnerSize: 30
            });

            //fix scrolling issue
            $uiViewScrollProvider.useAnchorScroll();

            //set the base url for api calls on our RESTful services
            RestangularProvider.setBaseUrl(appCfg.apiUrl);

            // add authentication token
            RestangularProvider.addFullRequestInterceptor(function(element,operation,what,url,headers,params) {

                var newHeaders = {},
                    newParams = {};

                newHeaders['Authorization'] = 'Bearer ' + localStorage.getItem('id_token');
                newParams['XDEBUG_SESSION'] = 'PHPSTORM';
                newParams['XDEBUG_SESSION_START'] = '10259';

                return {
                    headers: _.extend(headers,newHeaders),
                    params: _.extend(params,newParams)
                };

            });

            // add a response interceptor
            RestangularProvider.addResponseInterceptor(function(data, operation, what, url, response, deferred) {
                var extractedData= {};

                // to look for getList operations
                if (operation === "getList") {
                    // and handle the data and meta data
                    extractedData = data.data;
                    extractedData.meta = data.meta != undefined ? data.meta.pagination : null;
                } else {
                    extractedData = data.data;
                }

                return extractedData;
            });

            //facebook and google sign-up and sign-in
            $authProvider.facebook({
                clientId: '1098736150144517'
            });

            $authProvider.google({
                clientId: '769938820115-gfh33um4r7jla65n6trvmmohe9l9i2pg.apps.googleusercontent.com'
            });

            function redirectWhenLoggedOut($rootScope,$q, $injector) {

                return {

                    responseError: function(rejection) {
                        // Need to use $injector.get to bring in $state or else we get
                        // a circular dependency error
                        var $state = $injector.get('$state');

                        if(rejection.status == 401)
                        {
                            // If we get a rejection corresponding to one of the reasons
                            // in our array, we know we need to authenticate the user so
                            // we can remove the current user from local storage
                            localStorage.removeItem('user');
                            $rootScope.user = null;

                            // Send the user to the auth state so they can login
                            $state.go('auth');
                        }else if(rejection.status == 403) {
                            $state.go('denied');
                        }

                        // Instead of checking for a status code of 400 which might be used
                        // for other reasons in Laravel, we check for the specific rejection
                        // reasons to tell us if we need to redirect to the login state
                        var rejectionReasons = ['TOKEN-EXPIRED', 'TOKEN-INVALID'];

                        // Loop through each rejection reason and redirect to the login
                        // state if one is encountered
                        angular.forEach(rejectionReasons, function(value, key) {

                            if(rejection.data.error.code === value) {

                                // If we get a rejection corresponding to one of the reasons
                                // in our array, we know we need to authenticate the user so
                                // we can remove the current user from local storage
                                localStorage.removeItem('user');
                                $rootScope.user = null;
                                // Send the user to the auth state so they can login
                                $state.go('auth');
                            }
                        });

                        return $q.reject(rejection);
                    }
                };
            }

            // Setup for the $httpInterceptor
            $provide.factory('redirectWhenLoggedOut', redirectWhenLoggedOut);

            // Push the new factory onto the $http interceptor array
            $httpProvider.interceptors.push('redirectWhenLoggedOut');

            $authProvider.loginUrl = '/api/auth/authenticate';

            $urlRouterProvider.otherwise('/auth');

        })
        .run(['$rootScope','$state','$auth','Restangular','StateChecker',function($rootScope, $state,$auth,Restangular,StateChecker) {

            // $stateChangeStart is fired whenever the state changes. We can use some parameters
            // such as toState to hook into details about the state as it is changing
            var currentState = $state.current;

            Restangular.setDefaultHeaders({Authorization:'Bearer '+ $auth.getToken()});
            $rootScope.skipSomeASync = false;

            $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
                // Grab the user from local storage and parse it to an object
                var user = null;

                if (fromParams.skipSomeAsync) {
                    return;
                }

                event.preventDefault();

                function continueNavigation (name, toParams) {
                    fromParams.skipSomeAsync = true;
                    $state.go(name, toParams);
                }

                if(localStorage.getItem("user") != null)
                 {
                    user = JSON.parse(localStorage.getItem('user'));

                // If there is any user data in local storage then the user is quite
                // likely authenticated. If their token is expired, or if they are
                // otherwise not actually authenticated, they will be redirected to
                // the auth state because of the rejected request anyway

                    // The user's authenticated state gets flipped to
                    // true so we can now show parts of the UI that rely
                    // on the user being logged in
                    $rootScope.authenticated = true;

                    // Putting the user's data on $rootScope allows
                    // us to access it anywhere across the app. Here
                    // we are grabbing what is in local storage
                    $rootScope.user = user;

                    //fetch our authenticated user and save it again
                    Restangular.one('entities',user.entity.data.id).customGET('accounts')
                    .then(function(response) {
                            $rootScope.$emit('AccountsLoaded',response.plain());
                        },
                    function (response) {
                        continueNavigation('auth');
                    });

                    // If the user is logged in and we hit the auth route we don't need
                    // to stay there and can send the user to the main state
                    if(toState.name === "auth") {

                        // Preventing the default behavior allows us to use $state.go
                        // to change states
                        event.preventDefault();

                        // go to the "main" state which in our case is users
                        continueNavigation('dashboard');
                    }
                    else
                    {
                        continueNavigation(toState.name, toParams);
                    }
                }
                else if(StateChecker.stateIsPrivate(toState.name))
                {
                    $rootScope.redirectBack={ route: toState.name, params : { id : toParams.id  }};
                    continueNavigation('auth');
                }else if(toState.name == 'auth' && !user)
                {
                    continueNavigation('auth');
                }
                else
                {
                    continueNavigation(toState.name, toParams);
                }

            });

            $rootScope.$on('AccountsLoaded',function(event,accounts){
                $rootScope.accounts = accounts;
            });

            $rootScope.$on('ResponseError',function(event,errorParams){
                if(errorParams.modal)
                {
                    swal({
                        title: "Error",
                        text: errorParams.error.message,
                        type: "error",
                        confirmButtonText: "Ok"
                    });
                }
                else
                {
                    //growl.addErrorMessage(errorParams.error.message);
                    $.notify({
                        message: errorParams.error.message
                    },{
                        type: "danger",
                        allow_dismiss: true,
                        label: 'Cancel',
                        className: 'btn-xs btn-inverse',
                        placement: {
                            from: 'top',
                            align: 'right'
                        },
                        delay: 5000,
                        animate: {
                            enter: 'animated bounceIn',
                            exit: 'animated bounceOut'
                        },
                        offset: {
                            x: 20,
                            y: 20
                        }
                    });

                }
            });

            $rootScope.$on('ResponseSuccess',function(event,message){
                //console.log(message);
                if(message.modal)
                {
                    swal({
                        title: "",
                        text: message.message,
                        type: "success",
                        confirmButtonText: "Ok"
                    });

                }
                else
                {
                    //growl.addSuccessMessage(message.message);
                    $.notify({
                        message: message.message
                    },{
                        type: "success",
                        allow_dismiss: true,
                        label: 'Cancel',
                        className: 'btn-xs btn-inverse',
                        placement: {
                            from: 'top',
                            align: 'right'
                        },
                        delay: 5000,
                        animate: {
                            enter: 'animated bounceIn',
                            exit: 'animated bounceOut'
                        },
                        offset: {
                            x: 20,
                            y: 20
                        }
                    });
                }

            });
        }]);

    angular.element(document).ready(function() {
        angular.bootstrap(document, [appCfg.appName]);
        angular.element('.m-app-loading').remove();

    });

}(angular, appCfg));
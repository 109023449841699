function getDisplayName(customer)
{
    var displayName = 'none';

    if (customer.type == 'INDIVIDUAL')
    {
        displayName = customer.name + ' ' + customer.surname;
    }
    else
    {
        displayName = customer.company_name;
    }

    return displayName;
}

function getContactNumber(customer)
{
    var contactNumber = 'none';
    if (customer.type == 'INDIVIDUAL')
    {
        contactNumber = customer.mobile_number;
    }
    else
    {
        contactNumber = customer.telephone.length > 0 ? customer.telephone : customer.contact_number;
    }

    return contactNumber;
}
/**
 * Each module has a <moduleName>.module.js file.  This file contains the angular module declaration -
 * angular.module("moduleName", []);
 * The build system ensures that all the *.module.js files get included prior to any other .js files, which
 * ensures that all module declarations occur before any module references.
 */
(function (module) {
    var mapsLoaded = false;
    var pending = [];
    module.run(function ($window) {
        if (!mapsLoaded || !$window.google || !window.google.maps || !window.google.maps.places) {
            //http://maps.googleapis.com/maps/api/js?v=3.exp&libraries=places&sensor=false&callback=googleMapsLoaded
            $window.googleMapsLoaded = function () {
                delete $window.googleMapsLoaded;
                mapsLoaded = true;
            };
        }
    });
    module.directive('googlePlace', function () {
        var componentForm = {
            street_number: 'short_name',
            route: 'long_name',
            locality: 'long_name',
            administrative_area_level_1: 'short_name',
            country: 'long_name',
            postal_code: 'short_name'
        };
        var mapping = {
            street_number: 'number',
            route: 'street',
            locality: 'city',
            administrative_area_level_1: 'state',
            country: 'country',
            postal_code: 'zip'
        };
        // https://gist.github.com/VictorBjelkholm/6687484
        // modified to have better structure for details
        return {
            require: 'ngModel',
            scope: {
                ngModel: '=',
                details: '=?',
                restrict: '=?'
            },
            link: function (scope, element, attrs, model) {
                var restriction = attrs.restrict ? {country: attrs.restrict } : {};

                var options = {
                    types: attrs.googlePlace !== "" ? attrs.googlePlace.split(',') : [],
                    componentRestrictions: restriction
                };

                scope.gPlace = new google.maps.places.Autocomplete(element[0], options);

                google.maps.event.addListener(scope.gPlace, 'place_changed', function () {
                    var place = scope.gPlace.getPlace();
                    var details = place.geometry && place.geometry.location ? {
                        latitude: place.geometry.location.lat(),
                        longitude: place.geometry.location.lng()
                    } : {};
                    // Get each component of the address from the place details
                    // and fill the corresponding field on the form.
                    for (var i = 0; i < place.address_components.length; i++) {
                        var addressType = place.address_components[i].types[0];
                        if (componentForm[addressType]) {
                            var val = place.address_components[i][componentForm[addressType]];
                            details[mapping[addressType]] = val;
                        }
                    }
                    details.formatted = place.formatted_address;
                    details.placeId = place.place_id;

                    scope.$apply(function () {
                        scope.details = details; // array containing each location component
                        model.$setViewValue(details);
                    });

                    scope.$emit('g-places-autocomplete');
                });
            }
        };
    });
    // The name of the module, followed by its dependencies (at the bottom to facilitate enclosure)
}(angular.module("google.places", [])));
/**
 * INSPINIA - Responsive Admin Theme
 * Copyright 2014 Webapplayers.com
 *
 */

(function (angular, appCfg, appName) {
    'use strict';

    /**
     * pageTitle - Directive for set Page title - mata title
     */
    function pageTitle($rootScope, $timeout) {
        return {
            link: function(scope, element) {
                var listener = function(event, toState, toParams, fromState, fromParams) {
                    // Default title - load on Dashboard 1
                    var title = 'Inter Africa';
                    // Create your own title pattern
                    if (toState.data && toState.data.pageTitle) title = 'Inter Africa | ' + toState.data.pageTitle;
                    $timeout(function() {
                        element.text(title);
                    });
                };
                $rootScope.$on('$stateChangeStart', listener);
            }
        }
    };

    /**
     * sideNavigation - Directive for run metsiMenu on sidebar navigation
     */
    function sideNavigation() {
        return {
            restrict: 'A',
            link: function(scope, element) {
                // Call the metsiMenu plugin and plug it to sidebar navigation
                element.metisMenu();
            }
        };
    };

    /**
     * iboxTools - Directive for iBox tools elements in right corner of ibox
     */
    function iboxTools($timeout) {
        return {
            restrict: 'A',
            scope: true,
            templateUrl: appCfg.viewDir  + 'partials/ibox_tools.html',
            controller: function ($scope, $element) {
                // Function for collapse ibox
                $scope.showhide = function () {
                    var ibox = $element.closest('div.ibox');
                    var icon = $element.find('i:first');
                    var content = ibox.find('div.ibox-content');
                    content.slideToggle(200);
                    // Toggle icon from up to down
                    icon.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
                    ibox.toggleClass('').toggleClass('border-bottom');
                    $timeout(function () {
                        ibox.resize();
                        ibox.find('[id^=map-]').resize();
                    }, 50);
                },
                    // Function for close ibox
                    $scope.closebox = function () {
                        var ibox = $element.closest('div.ibox');
                        ibox.remove();
                    }
            }
        };
    };

    /**
     * minimalizaSidebar - Directive for minimalize sidebar
     */
    function minimalizaSidebar($timeout) {
        return {
            restrict: 'A',
            template: '<a class="navbar-minimalize minimalize-styl-2 btn btn-primary " href="" ng-click="minimalize()"><i class="fa fa-bars"></i></a>',
            controller: function ($scope, $element) {
                $scope.minimalize = function () {
                    $("body").toggleClass("mini-navbar");
                    if (!$('body').hasClass('mini-navbar') || $('body').hasClass('body-small')) {
                        // Hide menu in order to smoothly turn on when maximize menu
                        $('#side-menu').hide();
                        // For smoothly turn on menu
                        $timeout(function () {
                            $('#side-menu').fadeIn(500);
                        }, 100);
                    } else {
                        // Remove all inline style from jquery fadeIn function to reset menu state
                        $('#side-menu').removeAttr('style');
                    }
                }
            }
        };
    };

    /**
     * dropZone - Directive for Drag and drop zone file upload plugin
     */
    function dropZone() {
        return function(scope, element, attrs) {

            element.dropzone({
                url: "/upload",
                maxFilesize: 100,
                paramName: "uploadfile",
                autoProcessQueue: false,
                maxThumbnailFilesize: 5,
                init: function() {
                    //scope.files.push({file: 'added'});
                    this.on('success', function(file, json) {
                    });
                    this.on('addedfile', function(file) {
                        //scope.files.push(file);
                        scope.$apply(function(){
                            //alert(file);
                            //scope.files.push({file: 'added'});
                            scope.files.push(file);
                        });
                    });
                    this.on('drop', function(file) {
                        alert('file');
                    });
                }
            });
        }

    }

    function match() {
        return {
            require: 'ngModel',
            restrict: 'A',
            scope: {
                match: '='
            },
            link: function (scope, elem, attrs, ctrl) {
                scope.$watch(function () {
                    var modelValue = ctrl.$modelValue || ctrl.$$invalidModelValue;
                    return (ctrl.$pristine && angular.isUndefined(modelValue)) || scope.match === modelValue;
                }, function (currentValue) {
                    ctrl.$setValidity('match', currentValue);
                });
            }
        };

    }

    function checkPassword() {
        return {
            require: 'ngModel',
            restrict: 'A',
            scope: {
                match: '='
            },
            link: function (scope, elem, attrs, ctrl) {
                scope.$watch(function () {
                    var modelValue = ctrl.$modelValue || ctrl.$$invalidModelValue;

                    if(angular.isUndefined(modelValue))
                    {
                        modelValue = '';
                    }

                    var hasUpperCase = /[A-Z]/.test(modelValue);
                    var hasLowerCase = /[a-z]/.test(modelValue);
                    var hasNumbers = /\d/.test(modelValue);
                    var hasNonAlphas = /\W/.test(modelValue);
                    var hasLength = false;

                    ctrl.$setValidity('notUpperCase', false);
                    ctrl.$setValidity('notLowerCase', false);
                    ctrl.$setValidity('notNumbers', false);
                    ctrl.$setValidity('notNonAlphas', false);
                    ctrl.$setValidity('notLength', false);

                    if(angular.isDefined(modelValue) )
                    {
                        hasLength = (modelValue.length >= 8);
                    }

                    if(hasUpperCase)
                    {
                        ctrl.$setValidity('notUpperCase', true);
                    }
                    else
                    {
                        ctrl.$setValidity('notUpperCase', false);
                    }

                    if(hasLowerCase)
                    {
                        ctrl.$setValidity('notLowerCase', true);
                    }
                    else
                    {
                        ctrl.$setValidity('notLowerCase', false);
                    }


                    if(hasNumbers)
                    {
                        ctrl.$setValidity('notNumbers', true);
                    }
                    else
                    {
                        ctrl.$setValidity('notNumbers', false);
                    }

                    if(hasNonAlphas)
                    {
                        ctrl.$setValidity('notNonAlphas', true);
                    }
                    else
                    {
                        ctrl.$setValidity('notNonAlphas', false);
                    }

                    if(hasLength)
                    {
                        ctrl.$setValidity('notLength', true);
                    }
                    else
                    {
                        ctrl.$setValidity('notLength', false);
                    }

                    return (hasUpperCase && hasLowerCase && hasNumbers && hasNonAlphas && hasLength);

                }, function (currentValue) {
                    ctrl.$setValidity('checkPassword', currentValue);
                });
            }
        };
    }

    function checkPostalCodes() {
        return {
            require: 'ngModel',
            restrict: 'A',
            scope: false,
            link: function (scope, elem, attrs, ctrl) {
                scope.$watch(function () {
                    var modelValue = ctrl.$modelValue || ctrl.$$invalidModelValue;

                    if(angular.isUndefined(modelValue))
                    {
                        modelValue = '';
                    }

                    var foundObj = _.find(scope.postalCodes,{ 'code' : modelValue });
                    return foundObj !== undefined;

                }, function (currentValue) {
                    ctrl.$setValidity('checkPostalCode', currentValue);
                });
            }
        };
    }

    function previousState($history)
    {
        return {
            restrict: 'A',
            priority: 1,
            terminal: true,
            link: function (scope, element, attr) {
                element.bind('click',function(){
                    $history.back();

                });
            }
        };
    }

  /*  function clientSelector()
    {
        return {
            restrict: 'A',
            templateUrl : appCfg.viewDir + 'publishers/selector.html',
            controller : 'PublisherSelectorController',
            controllerAs : 'selectorCtrl'
        }
    }

    function callbackRest()
    {
        return {
            restrict: 'A',
            scope: false,
            link: function(scope, element, attrs, publisherCtrl) {
                console.log(scope.$parent.varTest);
            },
            templateUrl : appCfg.viewDir + 'publishers/callback_rest.html'
        };
    }


    function callbackSoap()
    {
        return {
            restrict: 'A',
            scope: false,
            link: function(scope, element, attrs, publisherCtrl) {
                //console.log(scope.$parent.publisher);
            },
            templateUrl : appCfg.viewDir + 'publishers/callback_soap.html'
        };
    }
*/
    function prettyPrint()
    {
        return {
            restrict: 'A',
            link: function postLink(scope, element, attrs) {
                //scope.$on('$viewContentLoaded', function(event){
                console.log("suppose to make it pretty....");
                console.log(element.html());
                element.html(prettyPrintOne(element.html(),'',true));
                // });
            }
        };
    }

    function appLoading($animate)
    {
        // Return the directive configuration.
        return({
            link: link,
            restrict: "C"
        });


        // I bind the JavaScript events to the scope.
        function link( scope, element, attributes ) {

            // Due to the way AngularJS prevents animation during the bootstrap
            // of the application, we can't animate the top-level container; but,
            // since we added "ngAnimateChildren", we can animated the inner
            // container during this phase.
            // --
            // NOTE: Am using .eq(1) so that we don't animate the Style block.

            $animate.leave( element.children().eq( 1 ) ).then(
                function cleanupAfterAnimation() {

                    // Remove the root directive element.
                    element.remove();

                    // Clear the closed-over variable references.
                    scope = element = attributes = null;

                }
            );

        }
    }

    function lineItems(TotalCalculator)
    {
        return {
            restrict: 'E',
            templateUrl: appCfg.viewDir + 'invoices/line_items.html'
        };
    }

    function transactionSummary()
    {
        return {
            restrict: 'E',
            scope: {
                transaction : '=',
                ownReference : '=',
                isAdmin : '='
            },
            templateUrl: appCfg.viewDir + 'payments/transaction_details.html'
        };
    }

    function cancelRequestModal($uibModal)
    {
        return {
            restrict: 'A',
            link: function(scope, element, attrs) {

                var item = {};
                var itemIndex = 0;
                console.log(attrs);

                $uibModal.open({
                    animation: true,
                    templateUrl: appCfg.viewDir + 'payments/cancel_modal.html',
                    controller: 'PaymentCancelController',
                    controllerAs: 'cancelCtrl',
                    size: 'md',
                    resolve: {
                        item: function () {
                            return item;
                        },
                        itemIndex: function () {
                            return itemIndex;
                        }
                    }
                });

            }

            };
    }


    /**
     * fancyBox - Directive for Fancy Box plugin used in simple gallery view
     */
    //function fancyBox() {
    //    return {
    //        restrict: 'A',
    //        link: function(scope, element) {
    //            element.fancybox({
    //                openEffect	: 'none',
    //                closeEffect	: 'none'
    //            });
    //        }
    //    }
    //}

    /**
     *
     * Pass all functions into module
     */
    angular
        .module(appName)
        .directive('pageTitle', ['$rootScope','$timeout',pageTitle])
        .directive('sideNavigation', sideNavigation)
        .directive('iboxTools',['$timeout',iboxTools])
        .directive('minimalizaSidebar', ['$timeout',minimalizaSidebar])
        .directive('dropZone', dropZone)
        .directive('match', match)
        .directive('checkPassword', checkPassword)
        .directive('previousState',['$history',previousState])
       // .directive('clientSelector', clientSelector)
       // .directive('callbackRest', callbackRest)
       // .directive('callbackSoap', callbackSoap)
        .directive('prettyPrint', prettyPrint)
       // .directive('mAppLoading', ['$animate',appLoading])
       // .directive('stats', Stats)
       // .directive('allVerifierStats', AllVerifierStats)
        .directive('checkPostalCodes', checkPostalCodes)
        .directive('lineItems', ['TotalCalculator',lineItems])
        .directive('transactionSummary', transactionSummary)
        .directive('cancelRequestModal', ['$uibModal',cancelRequestModal]);

})(angular, appCfg, appCfg.appName);

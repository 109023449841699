(function (angular, appCfg, appName) {
    'use strict';

    function config($stateProvider, $urlRouterProvider, $authProvider) {

        // Satellizer configuration that specifies which API
        // route the JWT should be retrieved from
        $authProvider.loginUrl = '/api/auth/authenticate';

        //$auth.setStorageType('sessionStorage');

        // Redirect to the auth state if any other states
        // are requested other than users
        $urlRouterProvider.otherwise('/auth');

        $stateProvider
            .state('auth', {
                url: '/auth',
                templateUrl: appCfg.viewDir + 'auth/login.html'
               // controller: 'AuthController as auth'
            })
            .state('register', {
                url: '/register/:type',
                templateUrl: appCfg.viewDir + 'auth/register_entity.html'
                // controller: 'AuthController as auth'
            })

            .state('termsofuse', {
                url: '/termsofuse',
                templateUrl: appCfg.viewDir + 'entities/IALLCTermsofUse.html'
            })

            .state('forgot_password', {
                url: '/forgot-password',
                templateUrl: appCfg.viewDir + 'auth/forgot_password.html'
                // controller: 'AuthController as auth'
            })
            .state('register_success', {
                url: '/register-success',
                templateUrl: appCfg.viewDir + 'auth/register_success.html'
                // controller: 'AuthController as auth'
            })
            .state('contact_us', {
                url: '/contact-us',
                data: { pageTitle: 'Contact Us' },
                templateUrl: appCfg.viewDir + 'contacts/contact_us.html'
            })
            .state('activate_account', {
                url: "/activate-account/:token",
                templateUrl: appCfg.viewDir + "auth/confirm_user.html",
                data: { pageTitle: 'Confirm User Account' },
                resolve: {
                    authenticated: ['$rootScope', function($rootScope) {
                        if(!$rootScope.authenticated)
                        {
                            return $location.path('/auth');
                        }
                    }]
                }

                //restrict:function(user){return user === undefined;}
            })
            .state('enable_two_factor', {
                url: "/enable-two-factor-auth",
                templateUrl: appCfg.viewDir + "auth/enable_two_factor.html",
                data: { pageTitle: 'Enable Two-factor Authorization'},
                resolve: {
                    authenticated: ['$rootScope', function($rootScope) {
                       if(!$rootScope.authenticated)
                       {
                           return $location.path('/auth');
                       }
                    }]
                }
            })
            .state('two_factor', {
                url: "/two-factor-auth",
                templateUrl: appCfg.viewDir + "auth/two_factor.html",
                data: { pageTitle: 'Two-factor Authorization'}
            })
            .state('reset_password', {
                url: "/reset-password/:token",
                templateUrl: appCfg.viewDir + "auth/reset_password.html",
                data: { pageTitle: 'Reset Password'}
            })
            .state('dashboard', {
                url: '/dashboard',
                templateUrl: appCfg.viewDir + 'dashboard/dashboard.html'
            })

            .state('dashboard.outfitter', {
                url: '/dashboard',
                templateUrl: appCfg.viewDir + 'dashboard/dashboard.html'
            })
            .state('daily_limits', {
                url: "/daily-limits",
                templateUrl: appCfg.viewDir + "entities/daily_limits.html",
                data: { pageTitle: 'Daily Transaction Limits' },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {

                        if(!RoleChecker.hasRole('outfitter'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }

                //restrict:function(user){return user === undefined;}
            })
            .state('settings', {
                url: "/settings/:crud",
                templateUrl: appCfg.viewDir + "entities/settings.html",
                data: { pageTitle: 'Profile Settings' },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {

                        if(!RoleChecker.can('outfitter','settings'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }

                //restrict:function(user){return user === undefined;}
            })
            .state('denied', {
                url: '/denied',
                templateUrl: appCfg.viewDir + 'auth/denied.html'
            })
            .state('not_found', {
                url: '/not-found',
                templateUrl: appCfg.viewDir + 'auth/not_found.html'
            })
            .state('users', {
                abstract: true,
                templateUrl: appCfg.viewDir + 'generic/content.html',
                data: {
                    pageTitle: 'Users'
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {

                        if(!RoleChecker.can('administrator','users'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('users.list', {
                url: '/users',
                templateUrl: appCfg.viewDir + 'users/list.html',
                data: {
                    action: { state: 'users.create.step_one', title: 'add a user'}
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {

                        if(!RoleChecker.can('administrator','users') && !RoleChecker.can('outfitter','users'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('users.outfitter_list', {
                url: '/outfitter-users/:outfitter_id',
                templateUrl: appCfg.viewDir + 'users/list.html',
                data: {
                    action: { state: 'users.create.step_one', title: 'add a user'}
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {

                        if(!RoleChecker.can('outfitter','users') && !RoleChecker.hasRole('administrator') )
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('users.edit', {
                abstract: true,
                url: "/users/edit/:user_id",
                templateUrl: appCfg.viewDir + "users/edit.html",
                data: {
                    pageTitle: 'Edit a User',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'users.list', title: 'Users'},
                        {state: 'users.edit', title: 'Edit a user'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {

                        if(!RoleChecker.can('administrator','users')  && !RoleChecker.can('outfitter','users'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('users.create', {
                abstract: true,
                url: "/users/create",
                templateUrl: appCfg.viewDir + "users/create.html",
                data: {
                    pageTitle: 'Create a User',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'users.list', title: 'Users'},
                        {state: 'users.edit', title: 'Create a user'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {

                        if(!RoleChecker.can('administrator','users') && !RoleChecker.can('outfitter','users'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('users.create.step_one', {
                url: "/step1",
                templateUrl: appCfg.viewDir + "users/step1_create.html",
                data: {
                    pageTitle: 'Create a User',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'users.list', title: 'Users'},
                        {state: 'users.create.step_one', title: 'Create a user'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {

                        if(!RoleChecker.can('administrator','users') && !RoleChecker.can('outfitter','users'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('users.create.step_two', {
                url: "/step2",
                templateUrl: appCfg.viewDir + "users/step2.html",
                data: {
                    pageTitle: 'Create a User',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'users.list', title: 'Users'},
                        {state: 'users.create.step_two', title: 'Create a user'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {

                        if(!RoleChecker.can('administrator','users') && !RoleChecker.can('outfitter','users'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('users.edit.step_one', {
                url: "/step1",
                templateUrl: appCfg.viewDir + "users/step1_edit.html",
                data: {
                    pageTitle: 'Edit a User',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'users.list', title: 'Users'},
                        {state: 'users.edit.step_one', title: 'Edit a user'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {

                        if(!RoleChecker.can('administrator','users') && !RoleChecker.can('outfitter','users'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('users.edit.step_two', {
                url: "/step2",
                templateUrl: appCfg.viewDir + "users/step2.html",
                data: {
                    pageTitle: 'Edit a User',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'users.list', title: 'Users'},
                        {state: 'users.edit.step_two', title: 'Edit a user'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {

                        if(!RoleChecker.can('administrator','users') && !RoleChecker.can('outfitter','users'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('system_fees', {
                url: '/system-fees',
                templateUrl: appCfg.viewDir + 'system_fees/list.html',
                data: {
                    pageTitle: 'Transaction Fees',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'system_fees', title: 'Transaction Fees'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {
                        if(!RoleChecker.can('administrator','system_fees'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('adjustments', {
                url: '/adjustments',
                templateUrl: appCfg.viewDir + 'adjustments/create.html',
                data: {
                    pageTitle: 'Adjustments',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'adjustments', title: 'Adjustments'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {
                        if(!RoleChecker.can('administrator','adjustments'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('recipients', {
                abstract: true,
                templateUrl: appCfg.viewDir + 'generic/content.html',
                data: {
                    pageTitle: 'recipients'
                }
            })
            .state('recipients.list', {
                url: '/recipients',
                templateUrl: appCfg.viewDir + 'recipients/list.html',
                data: {
                    pageTitle: 'Recipients',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'recipients.list', title: 'Recipients'}
                    ],
                    action: { state: 'recipients.create', title: 'add a recipient'}
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {
                        if(!RoleChecker.can('outfitter','recipient_payments'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })

            .state('recipients.requests', {
                url: '/recipients/requests/:type',
                templateUrl: appCfg.viewDir + 'payments/list.html',
                data: {
                    pageTitle: 'Requests',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'recipients.list', title: 'Recipients'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {
                        if(!RoleChecker.hasRole('outfitter'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('recipients.fees', {
                url: '/recipients/fees',
                templateUrl: appCfg.viewDir + 'recipients/fees.html',
                data: {
                    pageTitle: 'Fees',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'recipients.fees', title: 'Fees'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {
                        if(!RoleChecker.hasRole('outfitter'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('recipients.create', {
                url: '/recipients/create',
                templateUrl: appCfg.viewDir + 'recipients/create.html',
                data: {
                    pageTitle: 'Add a Recipient',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'recipients.list', title: 'Recipients'},
                        {state: 'recipients.create', title: 'Add a Recipient'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {
                        if(!RoleChecker.can('outfitter','recipient_payments'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('recipients.two_factor', {
                url: "/two-factor-auth",
                templateUrl: appCfg.viewDir + "auth/two_factor.html",
                data: { pageTitle: 'Two-factor Authorization',
                    breadcrumbs: [
                    {state: 'dashboard',title: 'Dashboard'},
                    {state: 'recipients.list', title: 'Recipients'},
                    {state: 'recipients.create', title: 'Add a Recipient'}
                    ]
                }
            })
            .state('recipients.payments', {
                url: '/recipients/payments/:type/:status',
                templateUrl: appCfg.viewDir + 'recipients/transactions.html',
                data: {
                    pageTitle: 'Recipient Payments',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'payments.link', title: 'Pending Payments'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {
                        if(!RoleChecker.can('administrator','process_recipient_payments') && !RoleChecker.can('outfitter','recipient_payments'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('recipients.pay', {
                url: '/recipients/pay?recipient_id',
                params: {
                    recipient_id: { value: null }
                },
                templateUrl: appCfg.viewDir + 'recipients/pay.html',
                data: {
                    pageTitle: 'Pay a Recipient',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'recipients.list', title: 'Recipients'},
                        {state: 'recipients.pay', title: 'Pay a Recipient'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {
                        if(!RoleChecker.can('outfitter','recipient_payments'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('recipients.view_payment', {
                url: '/recipients/view-payment/:transaction_id',
                params: {
                    recipient_id: { value: null }
                },
                templateUrl: appCfg.viewDir + 'recipients/view_payment.html',
                data: {
                    pageTitle: 'View Recipient Payment',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'recipients.list', title: 'Recipients'},
                        {state: 'recipients.pay', title: 'View Recipient Payment'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {
                        if(!RoleChecker.can('administrator','process_recipient_payments') && !RoleChecker.can('outfitter','recipient_payments'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('recipients.process_payment', {
                url: '/recipients/process-payment/:transaction_id',
                params: {
                    recipient_id: { value: null }
                },
                templateUrl: appCfg.viewDir + 'recipients/process_payment.html',
                data: {
                    pageTitle: 'Pay a Recipient',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'recipients.list', title: 'Recipients'},
                        {state: 'recipients.pay', title: 'Process Recipient Payment'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {
                        if(!RoleChecker.can('administrator','process_recipient_payments'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('recipients.view', {
                url: '/recipients/:recipient_id',
                templateUrl: appCfg.viewDir + 'recipients/view.html',
                data: {
                    pageTitle: 'View Recipient',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'recipients.list', title: 'Recipients'},
                        {state: 'recipients.view', title: 'View Recipient'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {
                        if(!RoleChecker.can('outfitter','recipient_payments'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('recipients.edit', {
                url: '/recipients/edit/:recipient_id',
                templateUrl: appCfg.viewDir + 'recipients/create.html',
                data: {
                    pageTitle: 'Edit a Recipient',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'recipients.list', title: 'Recipients'},
                        {state: 'recipients.edit', title: 'Edit a Recipient'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {
                        if(!RoleChecker.can('outfitter','recipient_payments'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('customers', {
                abstract: true,
                templateUrl: appCfg.viewDir + 'generic/content.html',
                data: {
                    pageTitle: 'Customers'
                }
            })
            .state('customers.list', {
                url: '/customers',
                templateUrl: appCfg.viewDir + 'customers/list.html',
                data: {
                    pageTitle: 'Customers',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'customers.list', title: 'Customers'}
                    ],
                    action: { state: 'customers.create', title: 'add a customer'}
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {
                        if(!RoleChecker.can('outfitter','invoices'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('customers.create', {
                url: '/customers/create',
                templateUrl: appCfg.viewDir + 'entities/customer_create.html',
                data: {
                    pageTitle: 'Add a Customer',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'customers.list', title: 'Customer'},
                        {state: 'customers.create', title: 'Add a Customer'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {
                        if(!RoleChecker.can('outfitter','recipient_payments'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('accounts', {
                abstract: true,
                templateUrl: appCfg.viewDir + 'generic/content.html',
                data: {
                    pageTitle: 'Accounts'
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {

                        if(!RoleChecker.can('outfitter','users'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('accounts.list', {
                url: '/accounts',
                templateUrl: appCfg.viewDir + 'accounts/list.html',
                data: {
                    pageTitle: 'Accounts',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'accounts.list', title: 'Invoices'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {
                        if(!RoleChecker.hasRole('outfitter'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('accounts.transactions', {
                url: '/accounts/:account_id/transactions',
                templateUrl: appCfg.viewDir + 'accounts/transactions.html',
                data: {
                    pageTitle: 'Transaction History',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'accounts.transactions', title: 'Transaction History'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {
                        if(!RoleChecker.can('outfitter','view_transaction_history') && !RoleChecker.hasRole('administrator'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('partners', {
                abstract: true,
                templateUrl: appCfg.viewDir + 'generic/content.html',
                data: {
                    pageTitle: 'Partners'
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {
                        if(!RoleChecker.can('administrator','partners'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('partners.list', {
                url: '/partners',
                templateUrl: appCfg.viewDir + 'partners/list.html',
                data: {
                    pageTitle: 'Partners',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'partners.list', title: 'Partners'}
                    ],
                    action: { state: 'partners.create', title: 'create a partner'}
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {
                        if(!RoleChecker.can('administrator','partners'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('partners.create', {
                url: '/partners/create',
                templateUrl: appCfg.viewDir + 'partners/create.html',
                data: {
                    pageTitle: 'Create a Partner',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'partners.list', title: 'Partners'},
                        {state: 'partners.create', title: 'Create a Partner'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {
                        if(!RoleChecker.can('administrator','partners'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('partners.view', {
                url: '/partners/:partner_id',
                templateUrl: appCfg.viewDir + 'partners/view.html',
                data: {
                    pageTitle: 'View Partner',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'partners.list', title: 'Partners'},
                        {state: 'partners.view', title: 'View Partner'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {
                        if(!RoleChecker.can('administrator','partners'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('partners.edit', {
                url: '/partners/edit/:partner_id',
                templateUrl: appCfg.viewDir + 'partners/create.html',
                data: {
                    pageTitle: 'Edit Partner',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'partners.list', title: 'Partners'},
                        {state: 'partners.view', title: 'Edit Partner'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {

                        if(!RoleChecker.can('administrator','partners'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('invoices', {
                abstract: true,
                templateUrl: appCfg.viewDir + 'generic/content.html',
                data: {
                    pageTitle: 'Invoices'
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {

                        if(!RoleChecker.hasRole('outfitter','invoices'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('invoices.quick', {
                url: '/quick',
                templateUrl: appCfg.viewDir + 'invoices/quick.html',
                data: {
                    pageTitle: 'Quick Payment',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'invoices.quick', title: 'Quick Payment'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {
                        if(!RoleChecker.hasRole('outfitter','invoices'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('invoices.list', {
                url: '/invoices',
                templateUrl: appCfg.viewDir + 'invoices/list.html',
                data: {
                    pageTitle: 'Invoices',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'invoices.list', title: 'Invoices'}
                    ],
                    action: { state: 'invoices.create', title: 'create an invoice'}
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {
                        if(!RoleChecker.hasRole('outfitter','invoices'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            }).state('invoices.customer_list', {
                url: '/invoices/:customer_id/customer',
                templateUrl: appCfg.viewDir + 'invoices/customer_list.html',
                data: {
                    pageTitle: 'Invoices',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'invoices.list', title: 'Invoices'}
                    ],
                    action: { state: 'invoices.create', title: 'create an invoice'}
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {
                        if(!RoleChecker.hasRole('outfitter','invoices'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('invoices.outfitter_list', {
                url: '/invoices/:outfitter_id/outfitter',
                templateUrl: appCfg.viewDir + 'entities/invoice_list.html',
                data: {
                    pageTitle: 'Invoices',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'invoices.list', title: 'Invoices'}
                    ],
                    action: { state: 'invoices.create', title: 'create an invoice'}
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {
                        if(!RoleChecker.hasRole('administrator','process_payment_requests'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('invoices.create', {
                url: '/invoices/create',
                templateUrl: appCfg.viewDir + 'invoices/create.html',
                data: {
                    pageTitle: 'Create an Invoice',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'invoices.list', title: 'Invoices'},
                        {state: 'invoices.create', title: 'Create an Invoice'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {
                        if(!RoleChecker.hasRole('outfitter','invoices'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('invoices.view', {
                url: '/invoices/:invoice_id',
                templateUrl: appCfg.viewDir + 'invoices/view.html',
                data: {
                    pageTitle: 'View an Invoice',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'invoices.list', title: 'Invoices'},
                        {state: 'invoices.view', title: 'View Invoice'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {
                        if(!RoleChecker.hasRole('outfitter','invoices') && !RoleChecker.hasRole('administrator'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('invoices.payments', {
                url: '/invoices/:invoice_id/payments',
                templateUrl: appCfg.viewDir + 'invoices/payments.html',
                data: {
                    pageTitle: 'View Payments',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'invoices.list', title: 'Invoices'},
                        {state: 'invoices.payments', title: 'View Payments'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {

                        if(!RoleChecker.hasRole('outfitter','invoices'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('payments', {
                abstract: true,
                templateUrl: appCfg.viewDir + 'generic/content.html',
                data: {
                    pageTitle: 'Payments'
                }
            })

            .state('payments.payouts_list', {
                url: '/payouts_list',
                templateUrl: appCfg.viewDir + 'reports/payouts_list.html',
                data: {
                    pageTitle: 'Commission Report',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'payouts_list',title: 'Commission Report'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {
                        if(!RoleChecker.can('administrator','system_fees'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('payments.wallets', {
                url: '/wallet_list',
                templateUrl: appCfg.viewDir + 'reports/wallets.html',
                data: {
                    pageTitle: 'Wallet Balance Report',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'wallet_list',title: 'Wallet Balance Report'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {
                        if(!RoleChecker.can('administrator','system_fees'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('payments.view_payment', {
                url: '/payments/view-payment/:transaction_id',
                params: {
                    recipient_id: { value: null }
                },
                templateUrl: appCfg.viewDir + 'payments/view_payment.html',
                data: {
                    pageTitle: 'View Request',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'payments.my_requests', title: 'My Requests'},
                        {state: 'payments.pay', title: 'View Request'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {
                        if(!RoleChecker.can('administrator','process_recipient_payments') && !RoleChecker.can('outfitter','recipient_payments'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('payments.my_requests', {
                url: '/payments/my_requests',
                templateUrl: appCfg.viewDir + 'payments/transactions.html',
                data: {
                    pageTitle: 'My Payment Requests',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'payments.my_requests', title: 'My Payment Requests'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {
                        if(!RoleChecker.can('administrator','process_recipient_payments') && !RoleChecker.can('outfitter','recipient_payments'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('payments.pay', {
                url: '/payments/pay/:transaction_id',
                templateUrl: appCfg.viewDir + 'payments/pay.html',
                data: {
                    pageTitle: 'Pay an Outfitter',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'payments.my_requests', title: 'My Payment Requests'},
                        {state: 'recipients.pay', title: 'Pay an Outfitter'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {
                        if(!RoleChecker.can('outfitter','recipient_payments'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('payments.admin_list', {
                url: '/admin/transactions',
                templateUrl: appCfg.viewDir + 'payments/admin_list.html',
                data: {
                    pageTitle: 'Payments',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'payments.list', title: 'Payments'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {
                        if(!RoleChecker.hasRole('administrator'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('payments.paypal', {
                url: '/admin/paypal_transactions',
                templateUrl: appCfg.viewDir + 'payments/paypal_list.html',
                data: {
                    pageTitle: 'Paypal Payments',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'payments.list', title: 'Payments'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {
                        if(!RoleChecker.hasRole('administrator'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('payments.link', {
                url: '/link-payments',
                templateUrl: appCfg.viewDir + 'payments/link.html',
                data: {
                    pageTitle: 'Payments',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'payments.link', title: 'Link Payment Requests'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {
                        if(!RoleChecker.can('administrator','process_payment_requests'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('payments.link_complete', {
                url: '/link-payments/:id',
                templateUrl: appCfg.viewDir + 'payments/complete_link.html',
                data: {
                    pageTitle: 'Link Payment',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'payments.link_complete', title: 'Link Payment'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {
                        if(!RoleChecker.can('administrator','process_payment_requests'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('payments.list', {
                url: '/list_payments/:type/:status',
                templateUrl: appCfg.viewDir + 'payments/list.html',
                data: {
                    pageTitle: 'Payments',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'payments.list', title: 'Payments'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {
                        if(!RoleChecker.hasRole('outfitter'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('complete_request', {
                url: '/complete-request/:id',
                templateUrl: appCfg.viewDir + 'payments/complete_request.html',
                data: {
                    pageTitle: 'Make a Payment',
                    breadcrumbs: []
                }
            })
            .state('request_paid', {
                url: '/request-paid',
                templateUrl: appCfg.viewDir + 'payments/request_paid.html',
                data: {
                    pageTitle: 'Payment request marked as paid',
                    breadcrumbs: []
                }
            })
            .state('payments.view', {
                url: '/pay/:id/:type',
                templateUrl: appCfg.viewDir + 'payments/view.html',
                data: {
                    pageTitle: 'Payments',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'payments.list', title: 'Payments'},
                        {state: 'payments.view', title: 'View Payment'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {

                        if(!RoleChecker.can('outfitter','recipient_payments') && !RoleChecker.hasRole('administrator','invoices'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('payments.resolve', {
                url: '/payments_resolve/:id',
                templateUrl: appCfg.viewDir + 'payments/complete_request.html',
                data: {
                    pageTitle: 'Payments',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'payments.list', title: 'Payments'},
                        {state: 'payments.request.view', title: 'View Payment'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {

                        if(!RoleChecker.can('outfitter','recipient_payments') && !RoleChecker.hasRole('administrator','invoices'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('agents', {
                abstract: true,
                templateUrl: appCfg.viewDir + 'generic/content.html',
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {

                        if(!RoleChecker.hasRole('administrator'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('agents.create', {
                url: '/agents-create/:entity_id',
                templateUrl: appCfg.viewDir + 'agents/create.html',
                data: {
                    pageTitle: 'Create an Agent',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'agents.list', title: 'Agents'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {

                        if(!RoleChecker.hasRole('administrator'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('agents.edit', {
                url: '/agents-edit/:entity_id/:agent_id',
                params: {
                    agent_id: { value: null }
                },
                templateUrl: appCfg.viewDir + 'agents/edit.html',
                data: {
                    pageTitle: 'Edit an Agent',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'agents.list', title: 'Agents'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {

                        if(!RoleChecker.hasRole('administrator'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('entities', {
                abstract: true,
                templateUrl: appCfg.viewDir + 'generic/content.html',
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {

                        if(!RoleChecker.can('administrator','entities'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('entities.list', {
                url: '/entities/:type',
                templateUrl: appCfg.viewDir + 'entities/list.html',
                data: {
                    pageTitle: 'Entities',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'entities.list', title: 'Entities'}
                    ],
                    action: { state: 'entities.create', title: 'add an entity'}
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {

                        if(!RoleChecker.can('administrator','entities'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('entities.create', {
                url: '/entities-create',
                templateUrl: appCfg.viewDir + 'entities/create.html',
                data: {
                    pageTitle: 'Entities',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'entities.list', title: 'Entities'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {

                        if(!RoleChecker.can('administrator','entities') && !RoleChecker.can('outfitter','invoices'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('entities.edit', {
                abstract: true,
                url: '/entities/edit/:entity_id',
                templateUrl: appCfg.viewDir + 'entities/edit.html',
                data: {
                    pageTitle: 'Edit Entity',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'entities.list', title: 'Entities'},
                        {state: 'entities.edit', title: 'Edit Entity'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {

                        if(!RoleChecker.can('administrator','entities')  && !RoleChecker.can('outfitter','invoices'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('entities.edit.step_one', {
                url: "/step1",
                templateUrl: appCfg.viewDir + "entities/step1_edit.html",
                data: {
                    pageTitle: 'Edit Entity',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'entities.list', title: 'Entities'},
                        {state: 'entities.edit.step_one', title: 'Edit an Entity'}
                    ],
                    action: { state: 'entities.edit.step_two', title: 'Next'}

                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {

                        if(!RoleChecker.can('administrator','entities') && !RoleChecker.can('outfitter','invoices'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('entities.edit.step_two', {
                url: "/step2",
                templateUrl: appCfg.viewDir + "entities/step2_edit.html",
                data: {
                    pageTitle: 'Edit an Entity',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'entities.list', title: 'Entities'},
                        {state: 'entities.edit.step_two', title: 'Edit an Entity'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {

                        if(!RoleChecker.can('administrator','entities') && !RoleChecker.can('outfitter','invoices'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
             })
            .state('entities.edit.step_three', {
                url: "/step3",
                templateUrl: appCfg.viewDir + "entities/step3_edit.html",
                data: {
                    pageTitle: 'Edit an Entity',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'entities.list', title: 'Entities'},
                        {state: 'entities.edit.step_two', title: 'Edit an Entity'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {

                        if(!RoleChecker.can('administrator','entities') && !RoleChecker.can('outfitter','invoices'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('customers.edit', {
                abstract: true,
                url: '/customer/edit/:entity_id',
                templateUrl: appCfg.viewDir + 'entities/customer_edit.html',
                data: {
                    pageTitle: 'Edit Customer',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'customers.list', title: 'Customers'},
                        {state: 'customers.edit', title: 'Edit Customer'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {

                        if(!RoleChecker.can('administrator','entities')  && !RoleChecker.can('outfitter','invoices'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('customers.edit.step_one', {
                url: "/step1",
                templateUrl: appCfg.viewDir + "entities/step1_edit.html",
                data: {
                    pageTitle: 'Edit Customer',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'customers.list', title: 'Customers'},
                        {state: 'customers.edit.step_one', title: 'Edit Customer'}
                    ],
                    action: { state: 'customers.edit.step_two', title: 'Next'}

                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {

                        if(!RoleChecker.can('administrator','entities') && !RoleChecker.can('outfitter','invoices'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('customers.edit.step_two', {
                url: "/step2",
                templateUrl: appCfg.viewDir + "entities/step2_edit.html",
                data: {
                    pageTitle: 'Edit Customer',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'entities.list', title: 'Customers'},
                        {state: 'entities.edit.step_two', title: 'Edit Customer'}
                    ]

                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {

                        if(!RoleChecker.can('administrator','entities') && !RoleChecker.can('outfitter','invoices'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            })
            .state('customers.view', {
                url: '/customers/view/:entity_id',
                templateUrl: appCfg.viewDir + 'entities/view.html',
                data: {
                    pageTitle: 'View Customer',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'customers.list', title: 'Customers'},
                        {state: 'customers.view', title: 'View Customers'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {

                        if(!RoleChecker.can('administrator','entities') && !RoleChecker.can('outfitter','invoices'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            }).state('entities.view', {
                url: '/entities/view/:entity_id',
                templateUrl: appCfg.viewDir + 'entities/view.html',
                data: {
                    pageTitle: 'View Entity',
                    breadcrumbs: [
                        {state: 'dashboard',title: 'Dashboard'},
                        {state: 'entities.list', title: 'Entities'},
                        {state: 'entities.view', title: 'View Entity'}
                    ]
                },
                resolve: {
                    authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {

                        if(!RoleChecker.can('administrator','entities'))
                        {
                            return $location.path('/denied');
                        }
                    }]
                }
            });
        //     .state('profiles.view.about', {
        //     url: '/about',
        //     templateUrl: appCfg.viewDir + 'profiles/view_about.html',
        //     resolve: {
        //         authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {
        //
        //             if(!RoleChecker.hasRole('administrator'))
        //             {
        //                 return $location.path('/denied');
        //             }
        //         }]
        //     }
        // })
        // .state('profiles.view.transactions', {
        //     url: '/profiles/{customer_id}/transactions',
        //     templateUrl: appCfg.viewDir + 'profiles/view_transactions.html',
        //     resolve: {
        //         authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {
        //
        //             if(!RoleChecker.hasRole('administrator'))
        //             {
        //                 return $location.path('/denied');
        //             }
        //         }]
        //     }
        // })
        // .state('profiles.view.beneficiaries', {
        //     url: '/profiles/{customer_id}/beneficaries',
        //     templateUrl: appCfg.viewDir + 'profiles/view_beneficiaries.html',
        //     resolve: {
        //         authenticated: ['$rootScope','$location', '$auth','RoleChecker', function($rootScope,$location, $auth,RoleChecker) {
        //
        //             if(!RoleChecker.hasRole('administrator'))
        //             {
        //                 return $location.path('/denied');
        //             }
        //         }]
        //     }
        // });
    }

    var routes = angular.module(appName).config(['$stateProvider', '$urlRouterProvider','$authProvider',config]);

    routes.run(['$rootScope','$state',function($rootScope, $state) {
        $rootScope.$state = $state;

        /*  $rootScope.$on('event:auth-loginRequired', function() {
         $state.go('login');
         });

         $rootScope.$on('event:auth-forbidden', function() {
         $state.go('access_denied');
         });
         */
    }]);



})(angular, appCfg, appCfg.appName);